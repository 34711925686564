import React, { Component } from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Header from "../components/header"
import Footer from "../components/footer"
import unescape from "../utils/unescape"
import { withTranslation } from "../services/i18next"
import SEO from "../components/seo"
import gatsbyi18Context from "../contexts/gatsby-i18n-context.js"

const MULTIMEDIA_API_URL = `${
  process.env.GATSBY_API_URL
}/wp-json/wp/v2/multimedia`

class MutlimedaSection extends Component {
  constructor(props) {
    super(props)

    this.limit = 20

    this.loadData = this.loadData.bind(this)

    this.state = {
      title: null,
      slug: null,
      posts: [],
      page: 1,
      maxPage: null,
      isLoading: false,
      isReady: false,
    }
  }

  async loadData() {
    const { page, posts } = this.state
    const { wordpressId } = this.props.pageContext

    try {
      this.setState({ isLoading: true })

      const data = await fetch(
        MULTIMEDIA_API_URL +
          `?_embed&per_page=${
            this.limit
          }&page=${page}&multimedia-sections=${wordpressId}`
      )
        .then(async response => {
          const body = await response.json()

          if (!response.ok) {
            throw new Error(JSON.stringify(body))
          }

          if (!this.maxPage) {
            this.setState({
              maxPage: +response.headers.get("x-wp-totalpages"),
            })
          }

          return body
        })
        .then(data => {
          return data.map(post => {
            const title = post.title.rendered
            const slug = post.slug
            const thumbnail =
              post._embedded &&
              post._embedded["wp:featuredmedia"] &&
              post._embedded["wp:featuredmedia"][0] &&
              post._embedded["wp:featuredmedia"][0]["media_details"] &&
              post._embedded["wp:featuredmedia"][0]["media_details"]["sizes"] &&
              post._embedded["wp:featuredmedia"][0]["media_details"]["sizes"][
                "medium_large"
              ] &&
              post._embedded["wp:featuredmedia"][0]["media_details"]["sizes"][
                "medium_large"
              ]["source_url"]

            return {
              title,
              thumbnail,
              slug,
            }
          })
        })

      this.setState({
        page: page + 1,
        posts: [...posts, ...data],
      })
    } finally {
      this.setState({
        isLoading: false,
      })
    }
  }

  async componentDidMount() {
    const { name: title, slug } = this.props.pageContext

    this.setState({
      title,
      slug,
    })

    await this.loadData()

    this.setState({
      isReady: true,
    })
  }

  render() {
    const { title, slug, posts, isLoading, isReady, maxPage, page } = this.state
    const { t, i18n } = this.props

    if (!title) {
      return null
    }

    const { pageContext } = this.props
    const { langKey: language } = pageContext

    const data = {
      language,
    }

    return (
      <gatsbyi18Context.Provider value={data}>
        <Layout className="has-header">
          <SEO title={title} />
          <Header />
          <main className="section container mb-4">
            <h4 className="heading mt-4 mb-4">
              <Link
                to={`${
                  i18n.language === "en" ? "/en" : ""
                }/multimedia/sections/${slug}`}
                className="px-2 py-2"
              >
                {unescape(title)}
              </Link>
            </h4>

            {isReady ? (
              <>
                <div className="row articles">
                  {posts.map((post, i) => (
                    <div className="col-lg-6" key={i.toString()}>
                      <article className="mb-4">
                        <div className="row">
                          <div className="col-lg-12">
                            <Link
                              to={`${
                                i18n.language === "en" ? "/en" : ""
                              }/multimedia/${post.slug}`}
                            >
                              <figure className="mb-0">
                                {post.thumbnail ? (
                                  <img src={post.thumbnail} alt={post.title} />
                                ) : null}
                              </figure>
                            </Link>
                          </div>
                          <div className="col-lg-12 mt-2">
                            <header>
                              <Link
                                to={`${
                                  i18n.language === "en" ? "/en" : ""
                                }/multimedia/${post.slug}`}
                              >
                                <h2>{unescape(post.title)}</h2>
                              </Link>
                            </header>
                          </div>
                        </div>
                      </article>
                    </div>
                  ))}
                </div>

                {posts.length && !isNaN(maxPage) && maxPage > page && (
                  <div className="col-12 d-flex justify-content-center">
                    <button
                      disabled={isLoading}
                      onClick={() => {
                        this.loadData()
                      }}
                      className="btn btn-theme"
                    >
                      {(isLoading && (
                        <>
                          {t("load more")} &nbsp;
                          <i className="fa fa-spinner fa-pulse" />
                        </>
                      )) || <>{t("loading")}</>}
                    </button>
                  </div>
                )}
              </>
            ) : (
              <div className="py-5 d-flex justify-content-center">
                {t("loading")} &nbsp;
                <span>
                  <i className="fa fa-spinner fa-pulse" />
                </span>
              </div>
            )}
          </main>
          <Footer />
        </Layout>
      </gatsbyi18Context.Provider>
    )
  }
}

export default withTranslation(MutlimedaSection)
